

.error {
  color: red;
}

/****************************  Home Page ****************************/
.head-logo{
  height: 50px;
  width: 200px;
  margin: 30px;
  cursor: pointer;
}
.homepage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }
  
  .background { 
    grid-area: 1 / 1 / 6 / 6;
    text-align: center;
  }

  .logo { 
    grid-area: 4 / 2 / 5 / 5;
    text-align: center;
    margin: auto;
   }
   
   .logo img{
    grid-area: 4 / 2 / 5 / 5; 
   }

   .form{
    margin-top: 30px;
    margin-bottom: 30px;
    padding: auto;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.877);
    width: 400px;
   }
  .button-login {
     grid-area: 2 / 2 / 4 / 5; 
     text-align: center;
    }

/*************************   Dashboard     ***************************/

 .dashboard {
  background-color: whitesmoke;
  padding-top: 50px;
  margin-right: 100px;
  margin-left: 100px;
  margin-bottom: 100px;
  box-shadow: 11px 10px 5px 1px #c2c2c2;
  border-radius: 10px;
  } 


  .dash-top { 
    font-family: 'Raleway', sans-serif;
 
    display: flex;
    flex-wrap: wrap;
   
    width: 100%;
  }


  .bank-container { 
  
    padding-bottom: 100px;
    padding-right: 80px;
    padding-left: 80px;

  
    }  

    #chart {
       width: 600px;
    
      
    }

  
   /********** Piggy Bank Container ***********/



  #tile-container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  #popover-basic{
    font-family: 'Raleway', sans-serif;
    box-shadow: 11px 10px 5px 1px #c2c2c2;

  }

/***************** Piggy Bank Tile ******************/


/* Tile Border Colors */
.pig-header-Emergency{
 text-align: center;
 border: 1px solid  rgb(0, 92, 230);
 align-self: flex-start;
 margin-bottom: 40px;
 margin-right: 40px;
 font-family: 'Raleway', sans-serif;

}
.pig-header-Personal{
  text-align: center;
  border: 1px solid  rgb(138, 43, 226);
  align-self: flex-start;
  margin-bottom: 40px;
  margin-right: 40px;
  font-family: 'Raleway', sans-serif;
 

 }
.pig-header-Retirement{
  text-align: center;
  border: 1px solid  rgb(255, 153, 0);
  align-self: flex-start;
  margin-bottom: 40px;
  margin-right: 40px;  
  font-family: 'Raleway', sans-serif;
 

 }

 /* Edit Menu */

.edit-menu {
  position: absolute;
  top: 0;
  left: 0;
}

.edit-option{
  padding: 1px;
  cursor: pointer;
  height: 2em;
}

.edit-option:hover {
  height: 4em
} 

.submit-img{
  height: 100px;
  width: 75px;
}

/* Pig Icon Colors */
.Emergency{
  color: rgb(0, 92, 230);
}
.Personal{
  color:  rgb(138, 43, 226);
}
.Retirement{
  color: rgb(255, 153, 0);
}

/* Progress bar colors */
.bg-Emergency{
 background-color:  rgb(0, 92, 230);
}
.bg-Personal{
  background-color: rgb(138, 43, 226);
}

.bg-Retirement{
  background-color: rgb(255, 153, 0);
}


/*************************   Accounts Page    ************************************/




  .account-container { 
    padding-bottom: 100px;
    padding-right: 80px;
    padding-left: 80px;
    font-family: 'Raleway', sans-serif;
  }


  .account-remove{
   right: 0;
  }


  .account-header{
    border: 1px solid #6221e4 ;

  }

  .account-card{
    border: 1px solid #6221e467 ;

  }



/*******************  Transaction Table ***********************/

.Withdrawal{
  background-color: rgb(255, 179, 179);
}

.Deposit{
  background-color:	rgb(189, 245, 189);
}




/********************* Navigation Bar ***************/

#react-burger-menu-btn{
  position: absolute; 
  right: 0px; 
  top: 0px; 
  z-index: 1; 
  width: 100%; 
  height: 100%; 
  margin: 0px; 
  padding: 0px; 
  border: none; 
  font-size: 0px; 
  background: transparent; 
  cursor: pointer;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
 
 
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #6221e4;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}



.nav-header{
  color: rgb(196, 126, 126);
  font-family: 'Shrikhand', cursive;
}
/* Individual item */
.bm-item {
  display: inline-block;
  font-style: oblique;
  color: white;
  margin-top: 20px;
  font-size: larger;
}

.bm-item:hover{
  display: inline-block;
  font-style: oblique;
  color: rgb(196, 126, 126);
  margin-top: 20px;
  font-size: larger;
}


/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.log-out{
  margin-top: 100px;
}

